import { useEffectOnce } from 'react-use';

import logger from '../logger/logger';
import { LoadingSpinner } from './LoadingSpinner';

export function GlobalLoading(props: {
  id?: string;
  debug?: string;
}): JSX.Element {
  useEffectOnce(() => {
    logger
      .scoped('global-loading')
      .info('active', { ...props, path: window.location.pathname });
  });
  return <LoadingSpinner id={props.id} />;
}
