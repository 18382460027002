import { useLocation, useSearchParams } from '@remix-run/react';
import { useCallback } from 'react';

import { type NavigateOptions } from '../utils/remix';

export function getQueryParam(key: string, search?: string): string | null {
  const params = new URLSearchParams(search || window.location.search);
  return params.get(key);
}

export function useQueryParam(key: string, search?: string): string | null {
  return getQueryParam(key, search);
}

export function useSetQueryParams(): (
  params: {
    key: string;
    value: string | null;
  }[],
  options?: NavigateOptions
) => void {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  return useCallback(
    (
      params: {
        key: string;
        value: string | null;
      }[],
      options?: NavigateOptions
    ) => {
      setSearchParams(
        (prev) => {
          const updated = new URLSearchParams(prev);
          for (const { key, value } of params) {
            if (prev.get(key) === value) continue;
            if (value === null) updated.delete(key);
            else updated.set(key, value);
          }
          return updated;
        },
        {
          state: location.state,
          ...options,
        }
      );
    },
    [location.state, setSearchParams]
  );
}

export function useSetQueryParam(): (
  key: string,
  value: string | null,
  options?: NavigateOptions
) => void {
  const setQueryParams = useSetQueryParams();
  return useCallback(
    (key: string, value: string | null, options?: NavigateOptions) => {
      setQueryParams([{ key, value }], options);
    },
    [setQueryParams]
  );
}
